.hktf-box {
  margin-top: 1rem;
  padding   : 1.4rem;

  background-color: rgb(219, 219, 219);

  border-radius: .5rem;

  &-error {
    background-color: rgb(255, 210, 210);
  }
}