.selected>.cst-node {
  &-waiting-input {
    border-top   : .4rem solid #888888;
    border-bottom: .4rem solid #888888;
    border-left  : .4rem solid #888888;
  }

  &-initialized {
    border-top   : .4rem solid #6bbce2;
    border-bottom: .4rem solid #6bbce2;
    border-left  : .4rem solid #6bbce2;
  }

  &-parse-ok {
    border-top   : .4rem solid #d8da5b;
    border-bottom: .4rem solid #d8da5b;
    border-left  : .4rem solid #d8da5b;
  }

  &-parse-nok {
    border-top   : .4rem solid #e26b6b;
    border-bottom: .4rem solid #e26b6b;
    border-left  : .4rem solid #e26b6b;
  }

  &-success {
    border-top   : .4rem solid #6ada5b;
    border-bottom: .4rem solid #6ada5b;
    border-left  : .4rem solid #6ada5b;
  }
}

.cst-node {
  background-color: white;

  min-width: 18rem;
  padding  : .2rem;

  box-shadow   : 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-top   : .4rem solid transparent;
  border-bottom: .4rem solid transparent;
  border-left  : .4rem solid transparent;

  transition: all .2s;

  &-read-only {
    cursor: default;
  }

  &-waiting-input {
    &::after {
      position        : absolute;
      content         : '';
      background-color: #888888;
      height          : 100%;
      width           : .4rem;
      top             : 0;
      right           : 0;
      transition      : all .2s;
    }
  }

  &-initialized {
    &::after {
      position        : absolute;
      content         : '';
      background-color: #6bbce2;
      height          : 100%;
      width           : .4rem;
      top             : 0;
      right           : 0;
      transition      : all .2s;
    }
  }

  &-parse-ok {
    &::after {
      position        : absolute;
      content         : '';
      background-color: #d8da5b;
      height          : 100%;
      width           : .4rem;
      bottom          : 0;
      right           : 0;
      transition      : all .2s;
    }
  }

  &-parse-nok {
    &::after {
      position        : absolute;
      content         : '';
      background-color: #e26b6b;
      height          : 100%;
      width           : .4rem;
      top             : 0;
      right           : 0;
      transition      : all .2s;
    }
  }

  &-success {
    &::after {
      position        : absolute;
      content         : '';
      background-color: #6ada5b;
      height          : 100%;
      width           : .4rem;
      bottom          : 0;
      right           : 0;
      transition      : all .2s;
    }
  }


  &__content {
    width: 100%;

    display           : flex;
    flex-direction    : column;
    justify-content   : stretch;
    // align-items    : center;
  }

  &__body {
    font-size: 1.6rem;
  }

  &__actions,
  &__actions-2 {
    width              : 100%;
    font-size          : 1.6rem;
    color              : #555555;
    padding            : .2rem 0rem;
    // background-color: green;

    display        : flex;
    flex-direction : row;
    justify-content: space-around;
    align-items    : center;

    transition: all .3s;
  }

  &__action-button {
    // width     : 100%;
    padding: .6rem 1.2rem;

    // background-color: rgb(255, 197, 197);
    border-radius: 1rem;

    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;

    &:hover {
      color: black;
    }
  }

  &__action-text {
    font-size: 1.6rem;
  }

  & .react-flow__handle {
    z-index: 2;
  }

  &__bottom-button {
    z-index: 2;

    background-color: white;

    position : absolute;
    top      : 90%;
    left     : 50%;
    transform: translateX(-50%);

    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center;

    color        : #999999;
    border       : .1rem solid #999999;
    border-radius: .2rem;
    padding      : .2rem .8rem;

    transition: all .3s;

    &>i {
      font-size: 1.6rem;
    }

    &-interactive {
      cursor: pointer;

      &:hover {
        // color : #6ada5b;
        // border: .1rem solid #6ada5b;
        color : #a33598;
        border: .1rem solid #a33598;
      }
    }
  }
}