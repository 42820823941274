.node-header {
  display        : flex;
  flex-direction : row;
  justify-content: space-between;
  align-items    : center;

  margin: 0rem 1rem;

  &__left {
    display        : flex;
    flex-direction : row;
    justify-content: stretch;
    align-items    : center;

    padding: .5rem;

    & .node-icon {
      margin-right: 1rem;
    }
  }


  &__title-container {
    display        : flex;
    flex-direction : column;
    justify-content: stretch;
    align-items    : flex-start;
  }

  &__type {
    font-size    : 1.1rem;
    color        : #777777;
    margin-bottom: .2rem;
  }

  &__name {
    font-size: 1.8rem;
  }

  &__menu-button {
    cursor   : pointer;
    font-size: 2rem;
  }

  // &.node-header__HTTP_TRIGGER .node-header__left {
  //   i {
  //     color           : #a33598;
  //     background-color: #ebd3ff;
  //   }
  // }

  // &.node-header__HTTP_CALL .node-header__left {
  //   i {
  //     color           : #a13838;
  //     background-color: #ffd3d3;
  //   }
  // }
}