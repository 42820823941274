.hktf-loader {
  display : block;
  position: relative;
  width   : 80px;
  height  : 80px;

  &.small {
    width : 20px;
    height: 20px;
  }
}

.hktf-loader {

  div {
    position                 : absolute;
    top                      : 33px;
    width                    : 13px;
    height                   : 13px;
    border-radius            : 50%;
    background               : #555555;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  &.small div {
    top   : 8px;
    width : 4px;
    height: 4px;
  }
}

.hktf-loader {
  div:nth-child(1) {
    left     : 8px;
    animation: hktf-loader1 1s infinite;
  }

  &.small div:nth-child(1) {
    left: 2px;
  }
}

.hktf-loader {
  div:nth-child(2) {
    left     : 8px;
    animation: hktf-loader2 1s infinite;
  }

  &.small div:nth-child(2) {
    left     : 2px;
    animation: hktf-loader2-small 1s infinite;
  }
}

.hktf-loader {
  div:nth-child(3) {
    left     : 32px;
    animation: hktf-loader2 1s infinite;
  }

  &.small div:nth-child(3) {
    left     : 8px;
    animation: hktf-loader2-small 1s infinite;
  }
}

.hktf-loader {
  div:nth-child(4) {
    left     : 56px;
    animation: hktf-loader3 1s infinite;
  }

  &.small div:nth-child(4) {
    left: 14px;
  }

}

@keyframes hktf-loader1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes hktf-loader3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes hktf-loader2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}


@keyframes hktf-loader2-small {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(6px, 0);
  }
}