.builder-navbar {
  padding   : 1rem 1rem;
  min-height: 2.5rem;

  display        : flex;
  flex-direction : row;
  justify-content: space-between;
  align-items    : center;

  &__left {
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center;
  }

  &__right {
    display        : flex;
    flex-direction : row;
    justify-content: flex-end;
    align-items    : center;
  }

  &__logo {
    .logo {
      height: 2.5rem;
    }
  }
}