.builder-toolbar {
  position: absolute;
  top     : 2rem;
  left    : 0rem;
  width   : 100vw;

  box-sizing: content-box;

  z-index: 10;

  &__flex {
    width: 100vw;

    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;

    &>* {
      margin         : 0rem 1rem;
      display        : flex;
      flex-direction : row;
      justify-content: center;
      align-items    : center;
    }
  }

  &__flow-name {
    background-color: white;
    border-radius   : 1rem;
    padding         : .5rem 1rem;
    font-size       : 2rem;
  }

  // &__buttons {
  //   margin: 0rem 0rem;

  //   &>*:not(:first-child) {
  //     margin: 0rem 1rem;
  //   }

  //   &>*:first-child {
  //     margin-right: 1rem;
  //   }

  // }
}