.left-bar {
  min-height: 100vh;
  width     : 6rem;


  display        : flex;
  flex-direction : column;
  justify-content: space-between;
  align-items    : stretch;

  background-color: #ffffff;

  &__logo {
    padding: 2rem .5rem;

    .logo {
      height: 3.5rem;
    }
  }

  &__menu {
    display       : flex;
    flex-direction: column;

    color: #aaaaaa;

    &-item {
      cursor : pointer;
      margin : 1.5rem 0rem;
      padding: 1rem .5rem;

      display        : flex;
      flex-direction : row;
      justify-content: center;
      align-items    : center;

      transition     : all .2s;
      // border-right: .4rem solid rgba(0, 0, 0, 0);

      &::after {
        position     : absolute;
        right        : 0px;
        content      : '';
        width        : 0rem;
        height       : 0rem;
        border-radius: 0.5rem;

        background-color: #9B5BEC;

        transition: all .2s;
      }

      &--active {
        // border-right: .4rem solid #9B5BEC;
        color: #9B5BEC;

        &::after {
          width : .4rem;
          height: 4rem;
        }
      }

      &:hover:not(&--active) {
        color: #9B5BEC;
      }
    }
  }
}