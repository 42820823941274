.flow-create {

  &__title {
    font-size     : 3rem;
    color         : #777777;
    font-weight   : 600;
    padding-bottom: 2rem;
  }

  &__form {}

  &__buttons {
    margin: 1rem 0rem;

    &>*:not(:first-child) {
      margin: 0rem 1rem;
    }

    &>*:first-child {
      margin-right: 1rem;
    }

  }
}