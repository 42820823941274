.main-layout {

  &__container {
    min-height: 100vh;
    min-width : 100vw;
  }

  &__left-bar {
    position      : fixed;
    display       : flex;
    flex-direction: column;

    min-height: 100vh;

    &>* {
      flex-grow: 2;
    }
  }

  &__content {
    // margin-left: 6rem;

    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: center;

    flex-grow: 2;
  }

  // &__actual-content {
  //   flex-grow: 2;
  // }
}