.builder-node-selector {
  position : absolute;
  top      : 50%;
  right    : 1rem;
  transform: translateY(-50%);

  padding         : .5rem;
  background-color: white;
  z-index         : 30;
  box-shadow      : 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius   : .5rem;

  &__content {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
  }

  &__icon {
    margin: 0rem .3rem;
    color : #555555;
    cursor: pointer;

    font-size: 2.8rem;

    transition: all .2s;

    &:hover {
      color: #111111;
    }
  }

  &__separator {
    content: "";
    margin : 1rem .3rem;
    width  : 100%;
    border : 1px solid #cccccc;
  }
}