.register-page {
  height: 100vh;
}

.register-modal {
  position : absolute;
  top      : 48%;
  left     : 50%;
  transform: translate(-50%, -50%);
  max-width: 40rem;

  padding      : 3rem;
  border-radius: 1rem;

  background-color: #ffffff;
  box-shadow      : rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

  text-align: center;

  .register-modal__header {
    padding: 1rem;

    .logo {
      width : 16rem;
      height: 7rem;
    }
  }

  .register-modal__title {
    padding: 1rem;
  }

  .register-modal__content {
    padding        : 1rem;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
  }

  .register-modal__input {
    width  : 100%;
    height : 4rem;
    padding: 2.5rem 2rem;
    margin : 1rem;

    font-size  : 2rem;
    line-height: 1.1rem;

    border-radius: .3rem;
    outline      : none;
    border       : 1px solid rgba($color: #555555, $alpha: 0.6);
  }

  .register-modal__button {
    width  : 100%;
    padding: 2rem;
    margin : 1rem;

    font-size  : 2rem;
    line-height: 1.1rem;

    border-radius   : .3rem;
    border          : none;
    background-color: #444444;
    color           : white;
    cursor          : pointer;
  }

  .register-modal__divider {
    display    : flex;
    align-items: center;
    width      : 100%;
    padding    : 2rem 0rem;

    &::before,
    &::after {
      content         : '';
      height          : 1px;
      background-color: silver;
      flex-grow       : 1;
    }

    &-with-text {
      display    : flex;
      align-items: center;
      width      : 100%;
      padding    : 2rem 0rem;

      &::before,
      &::after {
        content         : '';
        height          : 1px;
        background-color: silver;
        flex-grow       : 1;
      }

      &::before {
        margin-right: 1.5rem;
      }

      &::after {
        margin-left: 1.5rem;
      }
    }
  }

  #google-button {
    margin-top: 1rem;
  }

  // .register-modal__illustration {
  //   width: 40rem;
  // }

  .register-modal__login-text {
    padding    : 1rem;
    padding-top: 2rem;
  }

  .register-modal__login-link {
    text-decoration: underline;
    cursor         : pointer;

    transition: all .3s;

    &:hover {
      color: #9B5BEC;
    }
  }

  .register-modal__warning-text {
    padding-top: 1rem;
    font-size  : 1.5rem;
    text-align : left;

    &>.register-modal__button {
      margin-top: 3rem;
    }
  }

  .register-modal__warning-list {
    padding: 1rem 0rem;
  }

  .register-modal__warning-list-item {
    padding     : .5rem 0rem;
    padding-left: 2rem;
  }

  .register-modal__warning-checkbox {
    height        : 3rem;
    display       : flex;
    flex-direction: row;
    align-items   : center;
  }
}