.hktf-input-text-self {
  display       : inline-flex;
  flex-direction: row;
  align-items   : center;

  margin: 0rem .5rem;

  &__text {
    padding         : .3rem .5rem;
    background-color: #eaeaea;
  }

  &__icon {
    margin: 0rem .5rem;

    font-size: 1.4rem;
    color    : #555555;

    cursor: pointer;
  }
}