// .dashboard {
//   min-height: 100vh;
//   min-width : 100vw;

//   display        : flex;
//   flex-direction : row;
//   justify-content: space-between;

//   &__content {
//     padding: 1rem;
//   }
// }

.dashboard {
  // display        : flex;
  // justify-content: center;
  // align-items    : center;

  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;

  // &__container {
  //   width  : 50rem;
  //   padding: 1rem;

  //   text-align: center;
  // }

  margin: 3rem;

  &__title {
    font-size     : 4rem;
    padding-bottom: 5rem;
  }


  &__grid {
    display                 : grid;
    grid-template-columns   : 1fr 1fr 1fr;
    column-gap              : 3rem;
    row-gap                 : 3rem;
    // justify-items        : center;
    // align-items          : center;

    text-align: center;

    &-item {
      background-color: #ffffff;
      box-shadow      : 0px 0px 4px rgba(0, 0, 0, 0.2);
      border-radius   : .5rem;

      height : 10rem;
      padding: 1rem;
    }
  }
}