@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');

html {
  font-size  : 62.5%;
  font-family: 'Open Sans', sans-serif;
}

body {
  font-size: 14px;
  font-size: 1.4rem;
}

h1 {
  font-size: 24px;
  font-size: 2.4rem;
}

body {
  background-color      : #FFFFFF;
  // background-image   : linear-gradient(150deg, #3f3f4f 0%, #3a3a3f 74%);
  // background-color: #9921e8;
  // background-image: linear-gradient(315deg, #9921e8 0%, #5f72be 74%);
  // background-color: #4062bb;
  // background-image: linear-gradient(316deg, #4062bb 0%, #5200ae 74%);
  color                 : #222222;
}

a {
  text-decoration: inherit;
  color          : inherit;
  cursor         : auto;
}