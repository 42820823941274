.hktf-modal {
  position  : absolute;
  top       : 0;
  left      : 0;
  min-width : 100vw;
  min-height: 100vh;

  z-index: 100;

  &__flex {
    min-width      : 100vw;
    min-height     : 100vh;
    position       : absolute;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    z-index        : 101;
  }

  &__content {
    background-color: white;
    padding         : 2rem 4rem;
    border-radius   : .3rem;
  }

  &__bg {
    min-width       : 100vw;
    min-height      : 100vh;
    background-color: rgba(0, 0, 0, .6);
    z-index         : 100;
  }

  &__text {
    padding: 1rem 1rem;

  }

  &__actions {
    width  : 100%;
    padding: 1rem 1rem;

    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
  }
}