.user_profile {
  position: relative;

  &__button {
    position: relative;
    z-index : inherit;

    padding: .5rem;

    cursor: pointer;
    height: 100%;

    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;

    color: #888888;

    transition: all .2s;

    &:hover {
      color: #555555;
    }
  }

  &__popup {
    display : none;
    position: absolute;
    z-index : 25;

    top  : 3rem;
    right: 0rem;

    width  : 25rem;
    height : 25rem;
    padding: 1rem;

    flex-direction : column;
    justify-content: stretch;
    align-items    : stretch;

    background-color: #ffffff;
    box-shadow      : rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius   : .2rem;

    text-align: center;

    transition: all .5s;

    &:after {
      position     : absolute;
      right        : 0rem;
      top          : -0.8rem;
      width        : 0;
      height       : 0;
      content      : '';
      border-left  : 1rem solid transparent;
      border-right : 1rem solid transparent;
      border-bottom: 1rem solid #ffffff;

      border-radius: 1rem;
    }

    &--show {
      display: flex;
    }

    &-item {
      // display   : inline-block;
      font-size: 1.6rem;
      padding  : 1.5rem 3rem;

      &--link {
        cursor: pointer;

        &:hover {
          background-color: #999999;
        }
      }
    }
  }
}