.hktf-chip {
  background-color: #e0e0e0;

  padding      : .2rem 1rem;
  border-radius: .5rem;

  &-success {
    background-color: #daffd5;

  }
}