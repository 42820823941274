.popup-connection-status {
  z-index : 100;
  position: absolute;

  top   : 0rem;
  left  : 0rem;
  height: 100vh;
  width : 100vw;

  background-color: rgba(0, 0, 0, 0.5);

  display        : flex;
  flex-direction : column;
  justify-content: center;
  align-items    : center;

  &__popup {
    background-color: white;
    border-radius   : 1rem;

    padding    : 2rem 3rem;
    padding-top: 4rem;
  }

  &__content {
    height   : 100%;
    font-size: 2.5rem;

    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
  }
}