// RADIO

.hktf-radio {
  display               : flex;
  align-items           : center;
  position              : relative;
  padding               : .5rem;
  margin                : .3rem .5rem;
  cursor                : pointer;
  height                : 2rem;
  width                 : 100%;
  // font-size          : 22px;
  -webkit-user-select   : none;
  -moz-user-select      : none;
  -ms-user-select       : none;
  user-select           : none;
}

/* Hide the browser's default radio */
.hktf-radio input {
  position: absolute;
  opacity : 0;
  cursor  : pointer;
  height  : 0;
  width   : 0;
}

/* Create a custom radio */
.hktf-radio-fill {
  position        : absolute;
  top             : 0;
  left            : 0;
  height          : 2rem;
  width           : 2rem;
  background-color: #eee;
  border          : 1px solid #aaa;
  border-radius   : 1rem;
}

/* On mouse-over, add a grey background color */
.hktf-radio:hover input~.hktf-radio-fill {
  background-color: #ccc;
}

/* When the radio is checked, add a blue background */
.hktf-radio input:checked~.hktf-radio-fill {
  background-color: #9B5BEC;
}

.hktf-radio-label {
  margin-left: 2rem;
}