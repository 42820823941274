.hktf-button {
  padding: 1.2rem;
  display: inline-block;

  font-size  : 1.6rem;
  line-height: 1rem;

  cursor       : pointer;
  border-radius: .3rem;
  border       : none;

  &-primary {
    background-color: #9B5BEC;
    color           : white;

    &:disabled {}
  }

  &-secondary {
    background-color: #444444;
    color           : white;

    &:disabled {}
  }

  &-text {
    border          : 1px solid #444444;
    background-color: transparent;
    color           : #444444;

    &:disabled {
      border: 1px solid #aaaaaa;
      color : #aaaaaa;
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  &-wide {
    width: 100%;
  }

  &-big {
    padding: 2rem;

    font-size  : 2rem;
    line-height: 1.1rem;
  }

  &-medium {
    padding: 2rem;

    font-size  : 2rem;
    line-height: 1.1rem;
  }

  &-small {
    padding: 1rem;

    font-size  : 1.2rem;
    line-height: .8rem;
  }
}