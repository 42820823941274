// CHECKBOX

.hktf-checkbox {
  display            : inline;
  position           : relative;
  padding            : .5rem;
  margin             : .3rem 2rem;
  cursor             : pointer;
  font-size          : 22px;
  -webkit-user-select: none;
  -moz-user-select   : none;
  -ms-user-select    : none;
  user-select        : none;
}

/* Hide the browser's default checkbox */
.hktf-checkbox input {
  position: absolute;
  opacity : 0;
  cursor  : pointer;
  height  : 0;
  width   : 0;
}

/* Create a custom checkbox */
.hktf-checkbox-checkmark {
  position        : absolute;
  top             : -.5rem;
  left            : -.5rem;
  height          : 2rem;
  width           : 2rem;
  background-color: #eee;
  border          : 1px solid #aaa;
}

/* On mouse-over, add a grey background color */
.hktf-checkbox:hover input~.hktf-checkbox-checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.hktf-checkbox input:checked~.hktf-checkbox-checkmark {
  background-color: #9B5BEC;
}

/* Create the checkmark/indicator (hidden when not checked) */
.hktf-checkbox-checkmark:after {
  content : "";
  position: absolute;
  display : none;
}

/* Show the checkmark when checked */
.hktf-checkbox input:checked~.hktf-checkbox-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.hktf-checkbox .hktf-checkbox-checkmark:after {
  left             : 6px;
  top              : 3px;
  width            : 7px;
  height           : 10px;
  border           : solid white;
  border-width     : 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform    : rotate(45deg);
  transform        : rotate(45deg);
}