.builder-node-details {
  position: absolute;
  top     : 0px;
  left    : 0px;

  width : 100vw;
  height: 100%;

  z-index: 49;

  background-color: rgba(0, 0, 0, 0.5);

  display        : flex;
  flex-direction : column;
  justify-content: center;
  align-items    : center;

  transition: all .3s;

  &__popup {
    position: relative;

    z-index: 50;

    width : 98vw;
    height: 90vh;

    background-color: white;
    border-radius   : 1rem;

    padding: 1rem;

    transition: all .3s;
  }


  &.builder-node-details--hidden {
    display         : none;
    // opacity: 0;
    // transition   : -100%;
  }

  &__close {
    position : absolute;
    top      : 1.5rem;
    right    : 1.5rem;
    cursor   : pointer;
    padding  : .1rem;
    font-size: 2rem;
  }

  &__technical-name {
    margin   : .5rem 1rem;
    font-size: 1.4rem;
  }

  // #######
  // CONTENT
  // #######

  &__container {
    // padding: 1.5rem;

    display       : flex;
    flex-direction: column;

  }

  &__title {
    font-size     : 2rem;
    padding-bottom: 1rem;
  }

  &__content {
    display       : flex;
    flex-direction: column;

    margin: 0rem 1rem;

    &>* {
      padding: 1rem 0rem;
    }

    &>p {
      font-size  : 1.4rem;
      line-height: 1.4rem;
    }
  }

  &__columns {
    display        : flex;
    flex-direction : row;
    justify-content: space-evenly;
    align-items    : stretch;
    align-content  : stretch;
  }

  &__subtitle {
    display    : block;
    font-weight: 500;
    font-size  : 1.3rem;
    padding    : 1rem 0rem;
  }

  &__input-group {
    display       : flex;
    flex-direction: column;
  }

  &__input-text {
    padding: 0.5rem 1rem;
    margin : 1rem 0rem;
    color  : #9B5BEC;
  }

  &__input-note {
    font-size  : 1rem;
    font-weight: 300;
    color      : #999999;
  }
}