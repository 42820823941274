.workspace-create {


  display        : flex;
  flex-direction : column;
  align-items    : center;
  justify-content: center;

  &__title {
    font-size  : 2.4rem;
    font-weight: 600;
    color      : #777777;
  }
}