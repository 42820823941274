.notifications {
  position: relative;

  &__button {
    position: relative;
    z-index : inherit;

    padding: .5rem;

    cursor: pointer;
    height: 100%;

    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;

    color: #888888;

    transition: all .2s;

    &:hover {
      color: #555555;
    }
  }

  &__popup {
    display : none;
    position: absolute;
    z-index : 30;

    top  : 3rem;
    right: 0rem;

    width  : 25rem;
    height : 25rem;
    padding: 1rem;

    flex-direction : column;
    justify-content: stretch;
    align-items    : stretch;

    background-color: #ffffff;
    box-shadow      : rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius   : .2rem;

    text-align: center;
    cursor    : auto;

    &--show {
      display: flex;
    }

    &:after {
      position     : absolute;
      right        : 0rem;
      top          : -0.8rem;
      width        : 0;
      height       : 0;
      content      : '';
      border-left  : 1rem solid transparent;
      border-right : 1rem solid transparent;
      border-bottom: 1rem solid #ffffff;

      border-radius: 1rem;
    }

    &_title {
      font-size    : 2rem;
      font-weight  : 700;
      color        : #555555;
      margin-bottom: 1rem;
    }

    &_separator {
      content      : "";
      width        : 100%;
      margin-bottom: 1rem;
      border-bottom: 1px solid #d5d5d5;
    }
  }
}