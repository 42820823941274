.node-event-popup {
  position: absolute;
  top     : 0px;
  left    : 0px;

  width : 100vw;
  height: 100%;

  z-index: 49;

  background-color: rgba(0, 0, 0, 0.5);

  display        : flex;
  flex-direction : column;
  justify-content: center;
  align-items    : center;

  transition: all .3s;

  &__popup {
    position: relative;

    z-index: 50;

    background-color: white;
    border-radius   : 1rem;

    padding: 1rem;

    transition: all .3s;
  }

  &__title {
    width      : 100%;
    font-size  : 2rem;
    font-weight: 700;
    padding    : 1rem;
  }

  &__content {
    width  : 100%;
    padding: 1rem;
  }

  &__text {
    margin: 1rem 0rem;
  }

  &__options {
    display       : flex;
    flex-direction: column;
    margin        : 1rem 0rem;
  }

  &__option-item {
    display       : flex;
    flex-direction: row;
    align-items   : center;
  }

  &__actions {
    padding-top    : 1rem;
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: space-around;
    align-items    : center;
  }
}