.builder-samples-sidebar {
  position : absolute;
  top      : 50%;
  left     : 1rem;
  transform: translateY(-50%);

  padding: .5rem;
  z-index: 30;

  display        : flex;
  flex-direction : column;
  justify-content: center;
  align-items    : flex-start;

  &__row {
    margin: 1rem 0rem;

    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center;
  }

  &__button {
    margin          : 0rem .5rem;
    padding         : .5rem 1rem;
    background-color: #ffffff;

    border-radius   : 2rem;
    box-shadow      : 0px 0px 8px rgba(0, 0, 0, 0.15);
    // border       : 1px solid #888888;

    color      : #888888;
    font-size  : 2rem;
    font-weight: 700;

    cursor: pointer;

    transition: all .3s;

    &--icon {
      padding         : .3rem .5rem;
      background-color: #fafafa;
      font-weight     : 500;
    }

    &--selected {
      color: #a33598;
    }

    &:hover {
      color: #a33598;
    }
  }

}