.input {

  &-text {

    &__caption {
      font-size: 1.6rem;
      margin   : .5rem .5rem;
    }

    &__input {
      margin: .5rem .5rem;
    }
  }
}