.node-waiting {
  width          : 100%;
  height         : 100%;
  min-height     : 50rem;
  display        : flex;
  flex-direction : column;
  justify-content: center;
  align-items    : center;

  &__loader {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;

    font-size: 2rem;
    color    : #555555;
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform        : rotate(360deg);
  }
}