.node-additional-actions {
  display : none;
  position: absolute;

  top      : 50%;
  right    : -8.5rem;
  transform: translateY(-50%);

  flex-direction : column;
  justify-content: center;
  align-items    : flex-start;

  background-color: white;
  box-shadow      : rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius   : .2rem;

  z-index: 40;

  &__item {
    width    : 100%;
    font-size: 1.8rem;
    padding  : .8rem 1rem;

    cursor: pointer;

    &:hover {
      background-color: #888888;
    }
  }

  &--visible {
    display: flex;
  }
}