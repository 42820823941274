.workspace-selector {
  position: relative;

  &__button {
    // margin: .5rem 1rem;

    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;

    cursor: pointer;

    padding      : .3rem .1rem .3rem .5rem;
    border       : 1px solid #aaaaaa;
    border-radius: .5rem;

    transition: .3s all;

    &:hover {
      border: 1px solid #9B5BEC;
      color : #9B5BEC;
    }
  }

  &__text {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;

    &>i {
      margin-right: 1rem;
    }

    font-size: 1.4rem;
  }

  &__icon {
    margin-left: .5rem;

    font-size: 2rem;

    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
  }

  &__popup {
    position: absolute;
    z-index : 30;
    width   : 25rem;

    padding: .5rem 0rem;
    top    : 0rem;
    left   : 0rem;

    display        : none;
    flex-direction : column;
    justify-content: center;
    align-items    : center;

    background-color: white;
    box-shadow      : rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius   : .2rem;

    &--show {
      display: flex;
    }
  }

  &__popup-option {
    z-index: inherit;
    padding: 1rem 1.5rem;

    width: 100%;

    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center;

    font-size  : 1.6rem;
    font-weight: 600;

    color : #555555;
    cursor: pointer;

    transition: all .2s;

    &>i {
      margin-right: 1rem;
    }

    &:hover {
      background-color: #9B5BEC;
      color           : white;
    }
  }
}