.edge-button {


  &__foreign-object {
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: center;

    position: absolute;
    top     : 10rem;
    left    : 10rem;

    background: transparent;

    opacity   : 0;
    transition: all 0.2s;

    &:hover {
      opacity: 1;
    }
  }

  &__button {
    background     : #eee;
    color          : #222222;
    border         : 1px solid #fff;
    border-radius  : 50%;
    cursor         : pointer;
    font-size      : 1.8rem;
    padding        : 0rem 0rem;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: content;
  }
}