.add-button {
  position: relative;
  margin  : 0rem 2rem;

  display        : flex;
  flex-direction : column;
  justify-content: center;
  align-items    : center;

  &__button {
    padding: .5rem .3rem;

    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;

    border-radius   : 5rem;
    background-color: #cccccc;

    cursor: pointer;

    transition: all .3s;

    &:hover {
      background-color: #9B5BEC;
      color           : white;
    }
  }

  &__popup {
    display : none;
    position: absolute;
    z-index : 25;

    top          : 0rem;
    left         : 4rem;
    min-width    : 20rem;
    border-radius: 1rem;

    flex-direction : column;
    justify-content: stretch;
    align-items    : stretch;

    background-color: #f1f1f1;
    box-shadow      : rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

    text-align: center;

    &--show {
      display: flex;
    }
  }

  &__item {
    text-align: center;
    font-size : 1.6rem;
    padding   : 1rem 2rem;

    &--link {
      cursor: pointer;

      &:hover {
        background-color: #999999;
      }
    }
  }
}