.hktf-tooltip {
  position        : relative;
  display         : inline-block;
  // border-bottom: 1px dotted black;
  /* If you want dots under the hoverable text */

  & &__text {
    position: absolute;
    z-index : 75;

    visibility      : hidden;
    background-color: #333333;
    color           : #fff;
    text-align      : center;
    padding         : .6rem;
    border-radius   : .3rem;
    top             : 100%;
    left            : 50%;
    transform       : translateX(-50%);

    max-width: 20rem;
    font-size: 1rem;

  }

  &:hover &__text {
    visibility: visible;
  }
}