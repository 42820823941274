.top-bar {
  z-index   : 20;
  position  : relative;
  max-height: 8rem;

  display        : flex;
  flex-direction : column;
  justify-content: center;
  align-items    : stretch;

  // padding      : 1rem 2rem;
  // min-height: 2.5rem;
  &__row {
    position: relative;

    display        : flex;
    flex-direction : row;
    justify-content: center;

    &--main {
      height             : 4.5rem;
      // background-color: #f2f2f2;
      background-color   : #f3eff3;
    }

    &--second {
      margin-top: 1rem;
      height    : 2.5rem;

      &>* {
        border-bottom: 1px solid #d5d5d5;
      }
    }

    &_container {
      width: 160rem;

      display        : flex;
      flex-direction : row;
      justify-content: space-between;
      align-items    : center;
    }
  }


  &__left {
    position       : relative;
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center;
    height         : 100%;
  }

  &__middle {
    position       : relative;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    height         : 100%;
  }

  &__right {
    position       : relative;
    display        : flex;
    flex-direction : row;
    justify-content: flex-end;
    align-items    : center;
    height         : 100%;
  }

  &__logo {

    padding: 1rem;

    .logo {
      height: 2.5rem;
      width : 2.5rem;
      cursor: pointer;
    }

    margin-right: 3rem;
  }

  &__menu-item {
    position: relative;
    z-index : inherit;

    padding  : 0rem 1.5rem;
    height   : 100%;
    font-size: 1.5rem;

    border-radius: .5rem .5rem 0rem 0rem;

    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;

    cursor: pointer;

    transition: all .2s;

    &--active {
      background-color: #9B5BEC;
      color           : #ffffff;
    }

    &:hover:not(&--active) {
      color: #9B5BEC;
    }

    &--first {
      padding-left: 0rem;
    }

    &--last {
      padding-right: 0rem;
    }
  }

  &__search-bar {
    position : relative;
    font-size: 1.5rem;

    &>i {
      position: absolute;
      top     : .8rem;
      left    : .7rem;
      color   : #555555;
    }

    &>input {
      padding: .5rem 3.2rem;
      width  : 40rem;

      background-color: white;
      outline         : none;
      border          : 1px solid rgb(200, 200, 200);
      border-radius   : 1rem;

      font-size: 1.5rem;

      &:focus {
        outline: none;
      }
    }

  }

  &__submenu-closer {
    position: absolute;
    left    : 0px;
    top     : 0px;
    width   : 100vw;
    height  : 100vh;
    z-index : 10;
    display : none;

    &--shown {
      display: block;
    }
  }
}