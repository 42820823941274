.node-icon {
  margin : .1rem;
  padding: .3rem .3rem 0rem .3rem;

  border-radius   : .2rem;
  background-color: #d9d9d9;

  font-size: 2.4rem;
  color    : #777777;

  &__http-trigger {
    color           : #a33598;
    background-color: #ebd3ff;
  }

  &__http-call {
    color           : #a13838;
    background-color: #ffd3d3;
  }

  &--without-bg {
    background-color: transparent;
  }
}