.flows-list {

  &__title {
    font-size     : 3rem;
    color         : #777777;
    font-weight   : 600;
    padding-bottom: 2rem;
  }

  &__flows-table {
    margin: 1rem 0rem;

    color: #444444;

    border: 1px solid #444444;

    thead th {
      padding    : 1rem 2rem;
      font-weight: 700;
    }

    td {
      padding: 1rem 2rem;
    }
  }
}