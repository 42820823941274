.builder-mini-toolbar {
  position : absolute;
  transform: translateX(-50%);

  box-sizing: content-box;

  z-index: 10;

  display        : flex;
  flex-direction : row;
  justify-content: center;
  align-items    : center;


  &.builder-mini-toolbar--hidden {
    display: none;
  }

  &__icon {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;

    padding  : .5rem .3rem;
    font-size: 2rem;

    color     : #555555;
    cursor    : pointer;
    border    : 1px solid #555555;
    opacity   : .6;
    transition: all .3s;

    &--disabled {
      opacity: .2;
      cursor : not-allowed;
      border : .5px solid #555555;
    }

    &:not(.builder-mini-toolbar__icon--disabled) {
      &:hover {
        opacity: 1;
      }
    }
  }
}