.workspace-settings {
  margin: 2rem 3rem;

  &__title {
    margin: 2rem 0rem;

    font-size: 2.4rem;
  }

  &__subtitle {
    margin: 2rem 0rem;

    font-size: 2rem;
  }

  &__section {
    margin: 1rem 0rem;
  }

  &__users-table {
    margin: 1rem 1rem;

    border: 1px solid black;

    thead td {
      font-weight: 800;
    }

    td {
      padding: .5rem 2rem;
    }
  }
}